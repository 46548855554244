import React from 'react'
import { graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data }) => {
  const videos = data.allMarkdownRemark.nodes;
  console.log(videos);
  return (
    <Layout>
      <Seo title="CSICon 2022 Videos and Interviews" description="Are you getting ready for CSICon? Learn about new and returning visitors to CSICon this year, including: Neil deGrasse Tyson, Richard Wiseman, Kenny Biddle, Susan Gerbic, Penn Jillette, Naomi Oreskes, and Joseph Uscinski."/>
      <div className="row page-row">
        
          <div className="page-header">
            <h1>
              <strong>Interviews &amp; Videos</strong>
            </h1>
            <hr />
          </div>
          <div className="row">
            {videos.map(video => (
              <div id={video.frontmatter.slug} className="col-xl-6">
                <div>
                  <p>
                    <strong>{video.frontmatter.title}</strong>
                  </p>
                </div>
                <iframe 
                  src={video.frontmatter.videoSrcURL}
                  title={video.frontmatter.title}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope;   picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  height={400}
                  width="95%"
                  allowFullScreen
                />
                <hr />
              </div>
            ))}
          </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const videoQuery = graphql`
  query AllVideos {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "video" } } }
      sort: { fields: frontmatter___slug, order: ASC }
    ) {
        nodes {
          html
          frontmatter {
            title
            videoSrcURL
            slug
          }
        }
      }
  }
`